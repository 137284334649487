import { useState, useEffect } from "react"
import { CameraAltOutlined, Mic } from "@mui/icons-material"
import { Box, Button, Grid, Typography } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import styled from "styled-components"
import { colors } from "utils/styles"
import InterviewQuestions from "components/InterviewQuestions"
import { useRouter } from "hooks/useRouter"
import { InterviewSetup } from "components/InterviewSetup"
import { useUser } from "hooks/useUser"
import { toast } from "react-toastify"
import MainLayout from "components/layout/MainLayout"

export const StyledBox = styled(Box)`
    height: 80vh;
`

export const StyledGrid = styled.div`
    border: 2px solid ${({ isPermissionGranted }) => (isPermissionGranted ? colors.logoBlue : "#DBDFEA")};
    height: 170px;
    width: 200px;
    max-width: 240px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    text-align: center;
`

export const Interview = () => {
    const [component, setComponent] = useState("")
    const [videoUrl, setVideoUrl] = useState("")

    const renderComponent = () => {
        if (component === "camera-mic") {
            return <CameraMic setComponent={setComponent} />
        } else if (component === "interview-questions") {
            return <InterviewQuestions videoUrl={videoUrl} setVideoUrl={setVideoUrl} />
        } else {
            return <InterviewSetup setComponent={setComponent} />
        }
    }

    const { push } = useRouter()
    const { user, uniqueId } = useUser()

    useEffect(() => {
        if (user?.applicant[0]?.video_interview_status === 1) {
            push(`/background-check/${uniqueId}`)
        }
    }, [user, uniqueId, push])

    return (
        <MainLayout>
            <StyledBox>
                <Typography
                    sx={{ display: { xs: "none", md: "block" } }}
                    fontSize={15}
                    fontWeight={500}
                    color={colors.textGray}
                >
                    Video Interview
                </Typography>
                <Grid
                    sx={{ height: "inherit" }}
                    flexDirection="column"
                    container
                    gap={4}
                    alignItems="center"
                    justifyContent="center"
                >
                    {renderComponent()}
                </Grid>

                {/* {component === "interview-questions" && (
                <Grid container alignItems="center" justifyContent="space-between">
                    <div />
                    <Button disabled={!videoUrl} onClick={onNext} variant="contained">
                        Go to next step
                    </Button>
                </Grid>
            )} */}
            </StyledBox>
        </MainLayout>
    )
}

const CameraMic = ({ setComponent }) => {
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        camera: false,
        mic: false,
    })

    const getAccess = () => {
        if (state.camera && state.mic) {
            setComponent("interview-questions")
        } else {
            setLoading(true)
            navigator.mediaDevices
                .getUserMedia({ audio: true, video: true })
                .then(function (stream) {
                    setLoading(false)
                    if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
                        setState({
                            camera: stream.getVideoTracks().length > 0 ? true : false,
                            mic: stream.getAudioTracks().length > 0 ? true : false,
                        })
                    } else {
                        toast.error("Some permissions are missing.")
                    }
                })
                .catch(function () {
                    setLoading(false)
                    toast.error("Camera and Microphone permission not granted by user.")
                })
        }
    }

    return (
        <>
            <Typography variant="h5" fontWeight={500} color="primary">
                We need to access your camera & microphone.
            </Typography>
            <Grid onClick={getAccess} gap={2} container alignItems="center" justifyContent="center">
                <StyledGrid isPermissionGranted={state.camera} item xs={6} md={2.5} xl={1.5}>
                    <Box position="absolute" top="0" right="0" marginRight={1} marginTop={1}>
                        <CheckCircleIcon fontSize="medium" htmlColor={state.camera ? colors.logoBlue : "gray"} />
                    </Box>
                    <CameraAltOutlined
                        fontSize="large"
                        htmlColor={state.camera ? colors.logoBlue : "gray"}
                        width="60"
                    />
                </StyledGrid>
                <StyledGrid isPermissionGranted={state.mic} item xs={6} md={2.5} xl={1.5}>
                    <Box position="absolute" top="0" right="0" marginRight={1} marginTop={1}>
                        <CheckCircleIcon fontSize="medium" htmlColor={state.mic ? colors.logoBlue : "gray"} />
                    </Box>
                    <Mic fontSize="large" htmlColor={state.mic ? colors.logoBlue : "gray"} width="60" />
                </StyledGrid>
            </Grid>
            <Button disabled={loading} variant="contained" onClick={getAccess}>
                {loading ? "Please wait..." : state.camera && state.mic ? "Continue" : "Allow Access"}
            </Button>
        </>
    )
}
