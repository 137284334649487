import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { theme } from "styles/theme"
import { ThemeProvider } from "@mui/material"
import { UserProvider } from "context/UserContext"

import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <UserProvider>
                <ToastContainer theme="colored" />
                <App />
            </UserProvider>
        </BrowserRouter>
    </ThemeProvider>,
)
