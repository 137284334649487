import axios from "axios"

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || "https://erp.doktorsjouren.se/api"

const axiosInstance = axios.create({
    baseURL: API_DOMAIN,
})

setAuthorizationHeader("50894a2a32f7939:c7d6cec526ce3a0")

function setAuthorizationHeader(token = null) {
    if (token) {
        axiosInstance.defaults.headers.common.authorization = `token ${token}`
    } else {
        delete axiosInstance.defaults.headers.common.authorization
    }
}

export default axiosInstance
