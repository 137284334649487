import { Box, Button, Grid, Typography } from "@mui/material"

export const NotFound = () => {
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            sx={{ minHeight: "100vh" }}
        >
            <Box sx={{ textAlign: "center" }}>
                <Typography variant="h1" color="primary" fontWeight="700">
                    OOOPS
                </Typography>
                <Typography variant="h5" fontWeight="700">
                    4 0 4 - P A G E &nbsp; N O T &nbsp; F O U N D
                </Typography>
                <Typography mt={2} variant="h6" fontWeight="500">
                    The page you're looking for might have been removed, had its name changed, or is temporarily
                    unavailable
                </Typography>
                <Box marginTop={3}>
                    <Button
                        onClick={() => (window.location.href = "https://www.youmedico.com")}
                        size="large"
                        sx={{ borderRadius: 28, marginRight: 2 }}
                        variant="contained"
                    >
                        Go to Homepage
                    </Button>
                    <Button
                        onClick={() => (window.location.href = "https://stage.youmedico.com")}
                        size="large"
                        sx={{ borderRadius: 28 }}
                        variant="outlined"
                    >
                        Login
                    </Button>
                </Box>
            </Box>
        </Grid>
    )
}
