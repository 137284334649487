import { Box, Grid, ListItem, ListItemIcon, Typography } from "@mui/material"
import { NavLink } from "react-router-dom"
import { IconWaiting, InactiveIcon } from "./Icons"
import { STATUS } from "utils/constants"
import CancelIcon from "@mui/icons-material/Cancel"
import { useTheme } from "styled-components"
import { colors } from "utils/styles"
import { useRouter } from "hooks/useRouter"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { RadioButtonCheckedOutlined } from "@mui/icons-material"

export const SidebarItem = ({ name, icon, status, to }) => {
    const theme = useTheme()
    const { pathname } = useRouter()
    const primaryColor = theme.palette.primary.main
    const redColor = theme.palette.colors.red
    const greenColor = theme.palette.colors.lightGreen

    const currentStatus =
        pathname === to && status !== STATUS.REJECTED
            ? STATUS.IN_PROGRESS
            : status === "Pending" || !status
            ? STATUS.WAITING
            : status

    const color =
        currentStatus === STATUS.IN_PROGRESS
            ? primaryColor
            : currentStatus === STATUS.WAITING
            ? "gray"
            : currentStatus === STATUS.COMPLETED
            ? greenColor
            : currentStatus === STATUS.REJECTED
            ? colors.red
            : currentStatus === "Inactive"
            ? colors.yellow
            : colors.textGray

    return (
        <ListItem
            sx={{
                py: 2,
                bgcolor: pathname === to && colors.whisper,
                borderLeft: pathname === to ? `3px solid ${primaryColor}` : "",
            }}
            borderleft={"primary"}
            button
            disableRipple
        >
            <ListItemIcon>{icon(color)}</ListItemIcon>
            <Box sx={{ width: "100%" }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <NavLink onClick={e => e.preventDefault()} to={to} style={{ textDecoration: "none" }}>
                            <Typography fontSize={15} fontWeight={500} color={colors.textGray}>
                                {name}
                            </Typography>
                        </NavLink>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" gap={1} alignItems="center">
                            {currentStatus === STATUS.IN_PROGRESS ? (
                                <RadioButtonCheckedOutlined fontSize="medium" htmlColor={primaryColor} />
                            ) : currentStatus === STATUS.REJECTED ? (
                                <CancelIcon fontSize="medium" htmlColor={redColor} />
                            ) : currentStatus === STATUS.COMPLETED ? (
                                <CheckCircleIcon fontSize="medium" htmlColor={greenColor} />
                            ) : currentStatus === "Inactive" ? (
                                <InactiveIcon />
                            ) : (
                                <IconWaiting />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Typography fontSize={11} color={color} fontWeight={600} sx={{ textTransform: "uppercase" }}>
                    {currentStatus ?? "Current"}
                </Typography>
            </Box>
        </ListItem>
    )
}
