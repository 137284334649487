export const STATUS = {
    WAITING: "waiting",
    IN_PROGRESS: "current",
    COMPLETED: "Completed",
    REJECTED: "Rejected",
    CANCELLED: "cancelled",
}

export const NDA_STATUS = {
    DRAFT: "draft",
    PENDING: "pending",
    OVERDUE: "overdue",
    SIGNED: "signed",
    DECLINED: "declined",
    UNKNOWN: "unknown",
}

export const STEPS = {
    FORM: "Application Form",
    INTERVIEW: "Video Interview",
    BACKGROUND_CHECK: "Background Check",
    MANUAL_CHECK: "Manual Check",
    NDA: "Legal Agreement",
    BILLING: "Billing Information",
}
