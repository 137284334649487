import { List, ListItem, Typography } from "@mui/material"
import { StyledBox } from "pages/Interview"
import { colors } from "utils/styles"

const instructions = [
    "Insufficient qualifications or experience",
    "Inadequate credentials or licensure",
    "Unfavourable references or recommendations",
    "Prior disciplinary actions",
]

export const ContactForm = () => {
    return (
        <StyledBox>
            <Typography variant="h4" fontWeight={600} color={colors.textGray}>
                Thank you for your interest in joining our telemedicine platform. We regret to inform you that your
                application cannot be approved at this time due to one of the following reasons:
            </Typography>

            <List sx={{ fontWeight: "light", marginTop: 1, padding: 0, pl: 2, listStyleType: "disc" }}>
                {instructions.map((instruction, index) => (
                    <ListItem
                        sx={{
                            display: "list-item",
                            padding: 0,
                            lineHeight: 1.5,
                            fontSize: 16,
                        }}
                        key={index}
                    >
                        {instruction}
                    </ListItem>
                ))}
            </List>

            {/* <Box mb={2} mt={2} width={500}>
                <TextField id="outlined-multiline-flexible" label="Enter message" multiline rows={6} fullWidth />
            </Box>

            <Button variant="contained">Send message</Button> */}
        </StyledBox>
    )
}
