import { Box, Button, Checkbox, FormControlLabel, List, Paper, Typography } from "@mui/material"
import { StyledBox } from "pages/Interview"
import styled from "styled-components"
import { useState } from "react"

export const StyledCard = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    lineHeight: "50px",
    padding: "38px 60px",
    maxWidth: "40%",
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        padding: "25px"
    }
}))

// const instructions = [
//     "Be concise and to the point.",
//     "Speak clearly and audibly.",
//     "Answer truthfully and avoid discussing confidential patient information.",
//     "The automated interview can only be recorded once, so make sure you are ready before starting the recording.",
//     "Click Record to start, Next to proceed to the next question, and Stop when you finish answering.",
// ]

export const InterviewSetup = ({ setComponent }) => {
    const [read, setRead] = useState(false)
    return (
        <StyledBox>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    
                }}
            >
                <StyledCard elevation={3}>
                    <Typography fontWeight={600} color="primary" variant="h5" marginBottom={2}>
                        Automated Interview instructions:
                    </Typography>
                    {/* <p style={{ fontSize: 22, fontWeight: 800, padding: 0, margin: 0 }}>
                        Automated Interview instructions
                    </p> */}
                    <Typography fontWeight="light" textAlign="justify">
                        Welcome to the automated Interview. You will be presented with questions related to your
                        professional experience and you will be asked to record your answers.
                    </Typography>

                    <List sx={{ fontWeight: "light", marginBottom: 2, marginTop: 2, padding: 0, pl: 2, listStyleType: "disc" }}>
                        <p style={styles}>Be concise and to the point.</p>
                        <p style={styles}>Speak clearly and audibly.</p>
                        <p style={styles}>Answer truthfully and avoid discussing confidential patient information.</p>
                        <p style={styles}>
                            The automated interview can only be recorded once, so make sure you are ready before
                            starting the recording.
                        </p>
                        <p style={styles}>
                            Please ensure that your microphone and camera are turned on and working properly.
                        </p>
                        <p style={styles}>
                            Click Record to start, Next to proceed to the next question, and Stop when you finish
                            answering.
                        </p>
                    </List>
                    <Typography fontWeight="light" textAlign="justify" marginTop={1}>
                        Thank you for your participation.
                    </Typography>
                    {/* <Typography fontWeight={500} paddingY={1}>
                        Instructions:
                    </Typography> */}
                    <List sx={{ fontWeight: "light", padding: 0, pl: 2, listStyleType: "disc" }}></List>
                    <FormControlLabel
                        control={<Checkbox onChange={() => setRead(!read)} checked={read} color="primary" />}
                        label={<Typography color="primary">I have read the instructions.</Typography>}
                        color="primary"
                    />
                    <Box textAlign="center">
                        <Button disabled={!read} variant="contained" onClick={() => setComponent("camera-mic")}>
                            Start Setup
                        </Button>
                    </Box>
                </StyledCard>
            </Box>
        </StyledBox>
    )
}

const styles = {
    display: "list-item",
    padding: 1,
    margin: 0,
    paddingTop: 0.1,
    textAlign: "justify",
    lineHeight: 1.6,
    fontSize: 16,
}
