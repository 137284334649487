import { Paper } from "@mui/material"
import React from "react"
import styled from "styled-components"

export const StyledCard = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    lineHeight: "50px",
    overflow: "auto",
    maxHeight: "calc(100vh - 64px)",
    maxWidth: "min(500px, 100vw)",
    width: "100%",
}))

export const Modal = ({ open, setOpen, children }) => {
    return (
        open && (
            <>
                <div className="darkBG" onClick={() => setOpen(false)} />
                <StyledCard className="centered">
                    <div className="modal-content2">{children}</div>
                </StyledCard>
            </>
        )
    )
}
