const size = {
    mobile: "576px",
    tablet: "768px",
    laptop: "992px",
    desktop: "1200px",
}

const devices = {
    mobile: `min-width: ${size.mobile}`,
    tablet: `min-width: ${size.tablet}`,
    laptop: `min-width: ${size.laptop}`,
    desktop: `min-width: ${size.desktop}`,
}

const colors = {
    logoBlue: "#398ACA",
    darkOceanBlue: "#116582",
    lightGreen: "#71BB74",
    darkOceanBlue2: "#235b72",
    textGray: "#404040",
    darkBlue: "#203e51",
    lightBrown: "#d1b989",
    gold: "#e9c062",
    lightGold: "#f6e8b0",
    darkGold: "#b09167",
    yellow: "#f7b500",
    fbBlue: "#1877f2",
    fadedPurple: "#9164a2",
    textMain: "rgba(0, 0, 0, 0.87)",
    textSecondary: "rgba(0, 0, 0, 0.5)",
    stroke: "#dfdfdf",
    black: "#000000",
    white: "#ffffff",
    shadow: "rgba(214, 214, 214, 0.5)",
    shadowDark: "rgba(0, 0, 0, 0.1)",
    backgroundLightGrey: "rgba(0,0,0, 0.15)",
    backgroundGrey: "rgba(0,0,0, 0.25)",
    border: "rgba(0,0,0, 0.2)",
    switch: "#ccc",
    darkWhite: "#e7e7e7",
    darkGray: "#4a4a4a",
    gray: "#808080",
    lightGray: "#fafafa",
    borderLightGray: "#eaf0f2",
    smokedGray: "#a9a9a9",
    red: "#FF3D3D",
    advantagesColor: "#4B4B4B",
    whisper: "#F4f4f4",
}

const toRem = (px, base = 16) => `${px / base}rem`

export { devices, toRem, colors }
