import { Box, Button, Typography } from "@mui/material"
import React from "react"
import Lottie from "react-lottie"
import animationData from "utils/confetti1.json"

export const Congratulations = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    }

    return (
        <Box sx={{ height: "100vh" }}>
            <Lottie options={defaultOptions} />
            <Box
                sx={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <Typography variant="h4" fontWeight={600} color="#000" textAlign="center">
                    Congratulations!
                </Typography>
                <Typography variant="h2" padding={3} textAlign="center">
                    🎉🎉
                </Typography>
                <Typography variant="h5" color="gray" sx={{ width: { md: 600 } }} textAlign="center">
                    Welcome to Youmedico! <br /> To access your account, please check your email for the login
                    credentials we've sent you.
                </Typography>

                <Box mt={3} align="center">
                    <Button
                        onClick={() => (window.location.href = "https://youmedicostage.youpal.cloud/")}
                        variant="contained"
                    >
                        Take me to YouMedico App
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
