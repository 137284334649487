import React from "react"
import { Box } from "@mui/material"
import styled from "styled-components"

const StyledBox = styled(Box)`
    gap: 10px;
`

export const Logo = () => {
    return (
        <StyledBox>
            <img alt="logo" height={38} src="/assets/youmedico_logo.svg" />
            {/* <Typography sx={{ fontWeight: 700 }} variant="h6">
                Youmedico
            </Typography> */}
        </StyledBox>
    )
}
