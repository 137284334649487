import { Route, Switch } from "react-router-dom"
import {
    Congratulations,
    NDA,
    Billing,
    ApplicationForm,
    Interview,
    BackgroundCheck,
    ManualCheck,
    NotFound,
} from "pages"
import { Fragment } from "react"

function App() {
    return (
        <Fragment>
            <Switch>
                <Route exact path="/congratulations" component={Congratulations} />
                <Route exact path="/:id" component={ApplicationForm} />
                <Route path="/interview/:id" component={Interview} />
                <Route path="/background-check/:id" component={BackgroundCheck} />
                <Route path="/manual-check/:id" component={ManualCheck} />
                <Route path="/billing/:id" component={Billing} />
                <Route path="/nda/:id" component={NDA} />
                <Route component={NotFound} />
            </Switch>
        </Fragment>
    )
}

export default App
