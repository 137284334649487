import { Box, Button, Grid, Typography } from "@mui/material"
import { StyledBox } from "./Interview"
import { colors } from "utils/styles"
import { useRouter } from "hooks/useRouter"
import MainLayout from "components/layout/MainLayout"
import axiosInstance from "utils/axiosInstance"
import { useUser } from "hooks/useUser"
import { getUserData } from "services/user.service"
import { useCallback, useState } from "react"
import { useEffect } from "react"
import { NDA_STATUS } from "utils/constants"
import { useTheme } from "styled-components"

export const NDA = () => {
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState("")
    const { user, uniqueId, setUser } = useUser()
    const { push } = useRouter()
    const theme = useTheme()

    const pending = user?.nda[0]?.contract_state === NDA_STATUS.PENDING
    const signed = user?.nda[0]?.contract_state === NDA_STATUS.SIGNED
    const declined = user?.nda[0]?.contract_state === NDA_STATUS.DECLINED
    const overdue = user?.nda[0]?.contract_state === NDA_STATUS.OVERDUE
    const draft = user?.nda[0]?.contract_state === NDA_STATUS.DRAFT

    console.log({ signed, declined, overdue, draft })

    const sendNda = useCallback(() => {
        if (signed) {
            push(`/billing/${uniqueId}`)
        } else {
            axiosInstance
                .post(
                    `/method/erpnext.crm.doctype.oneflow_legal.oneflow_legal.create_applicant_oneflow_nda?unique_id=${uniqueId}`,
                )
                .then(resp => {
                    setState(resp.data.message.contract_state)
                    // toast.success("Please check your email")
                })
        }
    }, [uniqueId, push, signed])

    useEffect(() => {
        sendNda()
    }, [sendNda])

    const onNext = async type => {
        setLoading(true)
        try {
            const resp = await getUserData(uniqueId)
            setUser(resp)
            setLoading(false)
            if (type === "send") {
                push(`/billing/${uniqueId}`)
            }
        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }

    return (
        <MainLayout>
            <StyledBox>
                <Typography variant="h4" width={700} fontWeight={600} color={colors.textGray}>
                    Non Disclosure Agreement
                </Typography>
                <Typography marginTop={1} fontSize={16} width={900} fontWeight={500} color={colors.textGray}>
                    The contract has been sent to your email
                </Typography>
                <Typography mt={2}>Status</Typography>
                <Box display="flex" alignItems="center" gap={2} mt={1}>
                    <Box
                        sx={{
                            background: pending ? "rgba(57, 138, 202, 0.14)" : declined || overdue ? "#FFCDD2" :  "#f1f7f0",
                            fillOpacity: "0.1",
                            borderRadius: 4,
                            paddingX: 3,
                            paddingY: 1,
                        }}
                        fontSize="medium"
                        color={pending ? colors.logoBlue : declined || overdue ? colors.red : colors.lightGreen}
                    >
                        {pending
                            ? "Pending"
                            : declined
                            ? "Declined"
                            : overdue
                            ? "Overdue"
                            : draft
                            ? "Draft"
                            : signed ? "Signed": null}
                    </Box>
                    {overdue || declined || draft ? (
                        <Typography>
                            Please contact support at {" "}
                            <a
                                href="mailto:service@youmedico.com"
                                style={{ color: colors.logoBlue, cursor: "pointer" }}
                            >
                                service@youmedico.com
                            </a>
                        </Typography>
                    ) : (
                        <Typography>
                            If you’ve signed it but it still says pending, please click{" "}
                            <span
                                onClick={() => onNext("refresh")}
                                style={{ color: colors.logoBlue, cursor: "pointer" }}
                            >
                                {loading ? "Please wait..." : "here"}
                            </span>{" "}
                            to refresh it
                        </Typography>
                    )}
                </Box>

                {pending && <Button onClick={sendNda} variant="contained" sx={{ marginTop: 3 }}>
                    Resend contract
                </Button>}
            </StyledBox>
            <Grid sx={{ pt: 3 }} container alignItems="center" justifyContent="space-between">
                <div />
                <Button disabled={!signed} onClick={() => onNext("send")} variant="contained">
                    Go to next step
                </Button>
            </Grid>
        </MainLayout>
    )
}
