export const IconClipboard = ({ color = "#398ACA", bgColor = "#398ACA" }) => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="44" height="44" rx="9" fill={bgColor} fillOpacity="0.1" />
            <path
                d="M19.31 24.7L20.81 26.2L24.81 22.2"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20 16H24C26 16 26 15 26 14C26 12 25 12 24 12H20C19 12 18 12 18 14C18 16 19 16 20 16Z"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26 14.02C29.33 14.2 31 15.43 31 20V26C31 30 30 32 25 32H19C14 32 13 30 13 26V20C13 15.44 14.67 14.2 18 14.02"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const IconUser = ({ color = "#398ACA", bgColor = "#398ACA" }) => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="44" height="44" rx="9" fill={bgColor} fillOpacity="0.1" />
            <path
                d="M28.14 31.62C27.26 31.88 26.22 32 25 32H19C17.78 32 16.74 31.88 15.86 31.62C16.08 29.02 18.75 26.97 22 26.97C25.25 26.97 27.92 29.02 28.14 31.62Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25 12H19C14 12 12 14 12 19V25C12 28.78 13.14 30.85 15.86 31.62C16.08 29.02 18.75 26.97 22 26.97C25.25 26.97 27.92 29.02 28.14 31.62C30.86 30.85 32 28.78 32 25V19C32 14 30 12 25 12ZM22 24.17C20.02 24.17 18.42 22.56 18.42 20.58C18.42 18.6 20.02 17 22 17C23.98 17 25.58 18.6 25.58 20.58C25.58 22.56 23.98 24.17 22 24.17Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.58 20.58C25.58 22.56 23.98 24.17 22 24.17C20.02 24.17 18.42 22.56 18.42 20.58C18.42 18.6 20.02 17 22 17C23.98 17 25.58 18.6 25.58 20.58Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const IconTask = ({ color = "#398ACA", bgColor = "#398ACA" }) => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="44" height="44" rx="9" fill={bgColor} fillOpacity="0.1" />
            <path
                d="M22.37 18.8799H27.62"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.38 18.8799L17.13 19.6299L19.38 17.3799"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.37 25.8799H27.62"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.38 25.8799L17.13 26.6299L19.38 24.3799"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19 32H25C30 32 32 30 32 25V19C32 14 30 12 25 12H19C14 12 12 14 12 19V25C12 30 14 32 19 32Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const IconCalendar = ({ color = "#398ACA", bgColor = "#398ACA" }) => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="44" height="44" rx="9" fill={bgColor} fillOpacity="0.1" />
            <path
                d="M18 12V15"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26 12V15"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 21H25"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 25H22"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25 32H19C14 32 13 29.94 13 25.82V19.65C13 14.95 14.67 13.69 18 13.5H26C29.33 13.68 31 14.95 31 19.65V26"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31 26L25 32V29C25 27 26 26 28 26H31Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const IconList = ({ color = "#398ACA", bgColor = "#398ACA" }) => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="44" height="44" rx="9" fill={bgColor} fillOpacity="0.1" />
            <path
                d="M13 20C13 16.229 13 14.343 14.172 13.172C15.343 12 17.229 12 21 12H23C26.771 12 28.657 12 29.828 13.172C31 14.343 31 16.229 31 20V24C31 27.771 31 29.657 29.828 30.828C28.657 32 26.771 32 23 32H21C17.229 32 15.343 32 14.172 30.828C13 29.657 13 27.771 13 24V20Z"
                stroke={color}
                strokeWidth="1.5"
            />
            <path d="M18 22H26M18 18H26M18 26H23" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    )
}

export const IconBilling = ({ color = "#398ACA", bgColor = "#398ACA" }) => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="44" height="44" rx="9" fill={bgColor} fillOpacity="0.1" />
            <path
                d="M25.5715 23.0151C29.8319 23.0151 33.2857 21.4801 33.2857 19.5865C33.2857 17.693 29.8319 16.158 25.5715 16.158C21.311 16.158 17.8572 17.693 17.8572 19.5865C17.8572 21.4801 21.311 23.0151 25.5715 23.0151Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.8572 19.5864V29.8721C17.8572 31.7579 21.2857 33.3007 25.5715 33.3007C29.8572 33.3007 33.2857 31.7579 33.2857 29.8721V19.5864"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.2857 24.7293C33.2857 26.615 29.8571 28.1579 25.5714 28.1579C21.2857 28.1579 17.8571 26.615 17.8571 24.7293M25.4 12.7293C23.3968 11.4931 21.0651 10.8952 18.7143 11.015C14.4457 11.015 11 12.5579 11 14.4436C11 15.455 11.9943 16.3636 13.5714 17.015"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5714 27.3007C11.9943 26.6493 11 25.7407 11 24.7293V14.4436"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5714 22.1579C11.9943 21.5064 11 20.5979 11 19.5864"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const InactiveIcon = () => {
    return <img src="/assets/yellowcircle.png" width="23" height="23" alt="yellow" />
}

export const IconWaiting = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_218_26)">
                <path
                    d="M12 2V4M17 3.34L16 5.072M20.66 7L18.928 8M22 12H20M20.66 17L18.928 16M17 20.66L16 18.928M12 22V20M7 20.66L8 18.928M3.34 17L5.072 16M2 12H4M3.34 7L5.072 8M7 3.34L8 5.072"
                    stroke="url(#paint0_angular_218_26)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <radialGradient
                    id="paint0_angular_218_26"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(12 12) rotate(69.4438) scale(10.2529)"
                >
                    <stop />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
                <clipPath id="clip0_218_26">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
