import { createTheme } from "@mui/material"
import { colors } from "utils/styles"

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 992,
            lg: 1200,
            xl: 1920,
        },
    },

    palette: {
        colors: {
            borderLightGray: colors.borderLightGray,
            darkOceanBlue2: colors.darkOceanBlue2,
            lightBrown: colors.lightBrown,
            lightGray: colors.darkOceanBlue,
            shadowDark: colors.shadowDark,
            whisper: colors.whisper,
            textGray: colors.textGray,
            red: colors.red,
            lightGreen: colors.lightGreen,
        },
        primary: {
            main: colors.logoBlue,
        },
        white: {
            main: colors.white,
        },
        shadows: {
            primary: `0 2px 4px 0 ${colors.shadowDark}`,
        },
    },

    fontFamily: [
        "Avenir",
        "Inter",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Helvetica",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
    ].join(","),
})
