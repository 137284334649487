import { Paper } from "@mui/material"
import React from "react"
import styled from "styled-components"

export const StyledCard = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    overflow: "auto",
    height: "90vh",
    maxWidth: "min(500px, 100vw)",
    width: "100%",
}))

export const GetLaunchModal = ({ open, setOpen, children }) => {
    return (
        open && (
            <>
                <div className="darkBG" onClick={() => setOpen(false)} />
                <StyledCard className="centered">{children}</StyledCard>
            </>
        )
    )
}
