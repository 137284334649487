import { Box, Button, Grid, Typography } from "@mui/material"
import { StyledBox } from "./Interview"
import { colors } from "utils/styles"
import { STATUS } from "utils/constants"
import { useRouter } from "hooks/useRouter"
import { ContactForm } from "components/ContactForm"
import { useUser } from "hooks/useUser"
import MainLayout from "components/layout/MainLayout"
import { useState } from "react"
import { getUserData } from "services/user.service"
import { Fragment } from "react"
import { useEffect } from "react"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"

export const ManualCheck = () => {
    const [component, setComponent] = useState("")
    const { user, uniqueId } = useUser()

    const { push } = useRouter()

    useEffect(() => {
        if (user?.applicant[0]?.manual_check_status === STATUS.COMPLETED) {
            push(`/nda/${uniqueId}`)
        }
    }, [push, uniqueId, user])

    const renderComponent = () => {
        if (component === STATUS.REJECTED || user?.applicant[0]?.manual_check_status === STATUS.REJECTED) {
            return <ContactForm />
        } else return <Instructions setComponent={setComponent} />
    }

    return <MainLayout>{renderComponent()}</MainLayout>
}

const Instructions = ({ setComponent }) => {
    const { user, uniqueId, setUser } = useUser()
    const { push } = useRouter()

    const shouldBeDisabled = user?.applicant[0]?.manual_check_status === STATUS.COMPLETED

    const language = localStorage.getItem("wglang") ?? "en"

    useEffect(() => {
        const fetchUser = async () => {
            const resp = await getUserData(uniqueId, language)
            setUser(resp)
        }
        const interval = setInterval(() => fetchUser(), 3000)
        return () => {
            clearInterval(interval)
        }
    }, [setUser, uniqueId, user, language])

    const onNext = async () => {
        if (user?.applicant[0]?.manual_check_status === STATUS.COMPLETED) {
            push(`/billing/${uniqueId}`)
        } else if (user?.applicant[0]?.manual_check_status === STATUS.REJECTED) {
            setComponent(STATUS.REJECTED)
        } else {
            toast.error("Please complete this step first")
        }
        push(`/billing/${uniqueId}`)
    }

    return (
        <Fragment>
            <Helmet>
                <script>
                    {`
                            
                            Weglot.initialize({
                                api_key: 'wg_bc9842a957c13d432b7f0d1bd29db8f73'
                            });
                            
                            `}
                </script>
            </Helmet>
            <StyledBox sx={{ padding: { sm: 20, md: 0 }, width: { md: 1000 }, margin: "auto", textAlign: "center" }}>
                <Typography variant="h4" paddingTop={2} fontWeight={600} color={colors.textGray}>
                    Thank you for your interest in our telemedicine platform
                </Typography>
                <Typography paddingX={5} marginTop={2} fontSize={16} fontWeight={500} color={colors.textGray}>
                    In order to proceed with your registration, we will need to verify your medical license and
                    diplomas. We will review your documents as soon as possible and will be in touch with you shortly
                </Typography>
            </StyledBox>
            <Grid
                sx={{ pt: 3, flexDirection: { xs: "row-reverse", md: "row" } }}
                container
                alignItems="center"
                justifyContent="space-between"
            >
                <div />
                <Button disabled={!shouldBeDisabled} onClick={onNext} variant="contained">
                    Go to next step
                </Button>
            </Grid>
        </Fragment>
    )
}
