import { Button, Grid } from "@mui/material"
import { StyledBox } from "./Interview"
import { STATUS } from "utils/constants"
import { useRouter } from "hooks/useRouter"
import { useUser } from "hooks/useUser"
import { getUserData } from "services/user.service"
import { toast } from "react-toastify"
import { useState } from "react"
import { useEffect } from "react"
import MainLayout from "components/layout/MainLayout"
import { Helmet } from "react-helmet"

export const BackgroundCheck = () => {
    const [authToken, setAuthToken] = useState("")
    const { user, uniqueId, setUser } = useUser()
    const { push } = useRouter()

    const shouldBeDisabled = user?.background_check[0]?.status === STATUS.COMPLETED

    useEffect(() => {
        if (user && user?.background_check[0].status === STATUS.COMPLETED) {
            push(`/manual-check/${uniqueId}`)
        }
    }, [user, uniqueId])

    const language = localStorage.getItem("wglang") ?? "en"

    
    useEffect(() => {
        if (user) setAuthToken(user?.background_check[0]?.token_generated.split("=")[1])
        const fetchUser = async () => {
            const resp = await getUserData(uniqueId, language)
            setUser(resp)
        }
        const interval = setInterval(() => fetchUser(), 3000)
        return () => {
            clearInterval(interval)
        }
    }, [setUser, uniqueId, user, language])

    const onNext = () => {
        if (user?.background_check[0]?.status === STATUS.COMPLETED) {
            push(`/manual-check/${uniqueId}`)
        } else {
            toast.error("Please complete this step first")
        }
    }

    const lang = localStorage.getItem("wglang") ?? "en"

    return (
        <MainLayout>
            <Helmet>
                <script>
                    {`
                        Weglot.initialize({
                            api_key: 'wg_bc9842a957c13d432b7f0d1bd29db8f73'
                        });
                    `}
                </script>
            </Helmet>
            <StyledBox>
                <iframe
                    id="iframe"
                    title="background check"
                    allowFullScreen
                    frameBorder={0}
                    style={{ width: "100%", height: "100%" }}
                    src={`https://ui.idenfy.com/?authToken=${authToken}&lang=${lang}`}
                    allow="camera"
                ></iframe>

                <Grid sx={{ pt: 3, flexDirection: { xs: "row-reverse", md: "row" }}} container alignItems="center" justifyContent="space-between">
                    <div />
                    <Button disabled={!shouldBeDisabled} onClick={onNext} variant="contained">
                        Go to next step
                    </Button>
                </Grid>
            </StyledBox>
        </MainLayout>
    )
}
